* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(to top, #222831 0%, #222831 100%);
  width: 100%;
  direction: rtl;
  box-sizing: border-box;
  font-family: todoFont !important;
}
.card {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
  scroll-behavior: smooth;
}
.card::-webkit-scrollbar {
  width: 10px;
}
.card::-webkit-scrollbar-track {
  background-color: rgb(220, 221, 221);
  border-radius: 10px;
}
.card::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to top, #2e8a99 0%, #2e8a99 100%);
  border-radius: 10px;
}
.iconButton:hover {
  background-color: #ffffff !important;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  transform: scale(1.1) !important;
}

.iconButton {
  /* background-color: rgb(245, 245, 247) !important; */
  transition: all 0.2s ease !important;
}

.social-icon:hover {
  color: #2e8a99 !important;
  transition: all 0.2s linear;
  transform: scale(1.2);
  /* color: #0e295472; */
}

/* FONT CUSTTOM */
@font-face {
  font-family: todoFont;
  src: url("/public/fonts/IBMPlexSansArabic-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: todoFont;
  src: url("/public/fonts/IBMPlexSansArabic-Light.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: todoFont;
  src: url("/public/fonts/IBMPlexSansArabic-Bold.ttf");
  font-weight: bold;
}
/* FONT CUSTTOM */
